import React, { useState } from "react";
import { graphql } from "gatsby";
import { Layout, SEO, DefaultHeader, Footer, FadeInUp } from "components";
import { mapEdgesToNodes, filterOutDocsPublishedInTheFuture } from "../lib/helpers";
import styled from "styled-components";
import { useWindowSize } from "hooks";
import { Download, SearchIcon } from "assets/svgs";

export const query = graphql`
  query DownloadsPageQuery {
    banner: file(relativePath: { eq: "assets/images/downloads-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    downloads: allSanityDownload( sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          title
          pdf {
            asset {
              url
              size
            }
          }
          downloadsCategories {
            title
            id
          }
        }
      }
    }
    downloadsCategories: allSanityDownloadsCategory {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

const DownloadsPage = (props) => {
  const { data, errors } = props;
  const banner = (data || {}).banner;
  const downloads = (data || {}).downloads
    ? mapEdgesToNodes(data.downloads).filter(filterOutDocsPublishedInTheFuture)
    : [];
  const { windowWidth } = useWindowSize();
  const formatBytes = (a, b = 2) => {
    if (0 === a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return parseInt((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "Kb", "Mb", "Gb"][d];
  };
  const [searchQuery, setSearchQuery] = useState("");
  const handleChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };
  const searchData = downloads.filter((item) => {
    return item.title.toLowerCase().includes(searchQuery);
  });

  const [category, setCategory] = useState("all");


  const selectedCategory =  downloads.reduce(function(filtered, item) {
      item.downloadsCategories.map(itemCategory => {
        if(itemCategory.title.toLowerCase() === category.toLowerCase()){
          filtered.push(item);
        }
      });
      return filtered;
    }, []);


  const handleCategoryChange = (e) => {
    e.preventDefault();
    setCategory(e.target.value.toLowerCase());
  };



  const downloadsCategories = (data || {}).downloadsCategories ? mapEdgesToNodes(data.downloadsCategories) : [];


  return (
    <Layout>
      <SEO
        title="Downloads Section"
        description="LHS has compiled a free library of downloadable forms around Statutory Regulations, Best Practices and LHS Brochures. We hope to help you increase your safety awareness, and to provide precise information in statutory inspections across Ireland, EU, UK and USA."
      />
      <DefaultHeader
        title="Downloads Section"
        description="LHS has compiled a free library of downloadable documents around Statutory Regulations, Best Practices and LHS Brochures. We hope to help you increase your safety awareness, and to provide precise information in statutory inspections across Ireland, EU, UK and USA."
        imageFluid={banner}
      />
      <Container>
        <SearchBarLabel>Search By Keywords</SearchBarLabel>
        <SearchBar>
          <SearchIcon />
          <input
            onChange={handleChange}
            value={searchQuery}
            type="text"
            name="searchbar"
            placeholder="Enter Keywords"
          />
        </SearchBar>
        <CategoriesLabel>Search By Category</CategoriesLabel>

        <Categories>
          <Category
            value="all"
            type="submit"
            name="all"
            onClick={(e) => {
              handleCategoryChange(e);
            }}
          >
            Show All
          </Category>
          {downloadsCategories.map((node) => (
            <Category
              key={node.id}
              value={node.title}
              type="submit"
              name={node.title}
              onClick={(e) => {
                handleCategoryChange(e);
              }}
            >
              {node.title}
            </Category>
          ))}
        </Categories>
        <Downloads>
          <FadeInUp>
          <li>
            <Titles>
              <FileName>File Name</FileName>
              <FileSize>File Size</FileSize>
            </Titles>
          </li>
          {searchQuery !== "" && searchData.length >= 1 && (
            <>
              {searchData.map((node) => (
                <li key={node.id}>
                  <DownloadLink
                    href={node.pdf.asset.url}
                    target="blank"
                    onClick={(e) => {
                      if (windowWidth < 1150) {
                        e.preventDefault();
                        setTimeout(() => {
                          window.open(node.pdf.asset.url);
                        }, 1000);
                      } else {
                        return;
                      }
                    }}
                  >
                    <FileName>{node.title}</FileName>
                    <FileSize>
                      {formatBytes(node.pdf.asset.size)}
                      <Button type="button">
                        {windowWidth > 1149 && "View PDF"} <Download />
                      </Button>
                    </FileSize>
                  </DownloadLink>
                </li>
              ))}
            </>
          )}
          {searchQuery !== "" && !searchData.length && (
            <NoResults>No matching search results</NoResults>
          )}
          {(searchQuery === "" && category !== 'all') && (
            <>
              {selectedCategory.map((node) => (
                <li key={node.id}>
                  <DownloadLink
                    href={node.pdf.asset.url}
                    target="blank"
                    onClick={(e) => {
                      if (windowWidth < 1150) {
                        e.preventDefault();
                        setTimeout(() => {
                          window.open(node.pdf.asset.url);
                        }, 1000);
                      } else {
                        return;
                      }
                    }}
                  >
                    <FileName>{node.title}</FileName>
                    <FileSize>
                      {formatBytes(node.pdf.asset.size)}
                      <Button type="button">
                        {windowWidth > 1149 && "View PDF"} <Download />
                      </Button>
                    </FileSize>
                  </DownloadLink>
                </li>
              ))}
            </>
          )}
          {(searchQuery === "" && category === 'all') && (
            <>
              {downloads.map((node) => (
                <li key={node.id}>
                  <DownloadLink
                    href={node.pdf.asset.url}
                    target="blank"
                    onClick={(e) => {
                      if (windowWidth < 1150) {
                        e.preventDefault();
                        setTimeout(() => {
                          window.open(node.pdf.asset.url);
                        }, 1000);
                      } else {
                        return;
                      }
                    }}
                  >
                    <FileName>{node.title}</FileName>
                    <FileSize>
                      {formatBytes(node.pdf.asset.size)}
                      <Button type="button">
                        {windowWidth > 1149 && "View PDF"} <Download />
                      </Button>
                    </FileSize>
                  </DownloadLink>
                </li>
              ))}
            </>
          )}
          </FadeInUp>
        </Downloads>
      </Container>
      <Footer/>
    </Layout>
  );
};

const Category = styled.button`
  width: 100px;
  height: auto;
  padding: 0.5rem;
  background: transparent;
  color: #f94c00;
  margin-right: 1rem;
  border: none;
  outline: none;
  font-size: 0.875rem;
  border-radius: 0.5em;
  transition: 0.5s;
  border: 1px solid #f94c00;
  cursor: pointer;
  &:hover {
    background: #f94c00;
    color: white;
  }
`;

const CategoriesLabel = styled.h4`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: 1.25em;
  color: #243f93;
  grid-column: 1/7;
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/7;
  }
`;

const Categories = styled.div`
  grid-column: 1/7;
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/7;
    margin-bottom:4rem;
  }
  display: flex;
  justify-content: flex-start;
  width: 100%;

`;

const NoResults = styled.li`
  width: 100%;
  padding: 0.75rem 0.4rem;
  text-decoration: none;
  background: transparent;
  color: #ff7979;
  font-size: 1rem !important;
  font-weight: 300;
  margin: 0.25rem 0;
  border-radius: 3px;
  transition: 0.5s;
`;

const SearchBarLabel = styled.h4`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: 1.25em;
  color: #243f93;
  grid-column: 1/7;
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/7;
  }
`;

const SearchBar = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  grid-column: 1/7;
  border-bottom: 1px solid #232528;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 0.25rem;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    path {
      stroke: #959c9b;
    }
  }
  input {
    border: none;
    outline: none;
    font-size: 0.875rem;
    font-weight: 300;
    padding: 0.5rem;
    width: 100%;

    &:focus {
      border: none;
      outline: none;
    }
    &::placeholder {
      color: #959c9b;
    }
  }
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/7;
  }
`;

const Button = styled.button`
  outline: none;
  border: none;
  background: #f94c00;
  color: white;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  padding: 0.5rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  opacity: 0;
  svg {
    width: 1rem;
    height: 1rem;
    path {
      fill: white;
    }
  }
  @media (min-width: 1150px) {
    svg {
      margin-left: 0.5rem;
    }
  }
`;

const FileSize = styled.span`
  grid-column: 5/7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 1150px) {
    grid-column: 8/11;
  }
`;

const FileName = styled.span`
  grid-column: 1/5;
  @media (min-width: 1150px) {
    grid-column: 1/7;
  }
`;

const Titles = styled.p`
  width: 100%;
  font-size: 0.875rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 0.625rem;
  @media (min-width: 1150px) {
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 1.25rem;
  }
`;

const DownloadLink = styled.a`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 0.625rem;
  align-items: center;
  padding: 0.75rem 0.4rem;
  text-decoration: none;
  background: transparent;
  color: #35a8e0;
  font-size: 1rem;
  font-weight: 300;
  margin: 0.25rem 0;
  border-radius: 3px;
  transition: 0.5s;
  &:hover {
    background: white;
    color: black;
  }
  &:hover ${Button} {
    opacity: 1;
  }

  @media (min-width: 1150px) {
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 1.25rem;
  }
`;

const Downloads = styled.ul`
  width: 100%;
  background: #eaf6ff;
  list-style: none;
  grid-column: 1/7;
  font-size: 0;
  padding: 0.5rem;
  transition: 0.5s;
  li {
    font-size: 0.875rem;
    &:first-child {
      padding: 0.5rem;
    }
  }
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Container = styled.section`
  width: 1440px;
  max-width: 100%;
  margin: 5rem auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1rem;
  @media (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

export default DownloadsPage;
